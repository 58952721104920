<template>
<div class="modal fade" id="ViewMessagesModal" tabindex="-1" role="dialog" aria-labelledby="ViewMessagesModal" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Reasons</h1>
                <button v-if="!isEditModalActive" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body" >
                <div>
                    <div class="row d-flex justify-content-center" v-if="!isEditModalActive">

                        <div class="col-12 col-md-12" v-for="(callRequestMessage,index) in callRequestMessages " :key="index">
                            <div class="d-flex ">
                                <div class="" style="flex-shrink: 0">
                                    <img class="rounded-avatar" :src="callRequestMessage?.creator?.avatar" alt="">
                                </div>
                                <div class="ml-1">
                                    <h5 class="list-title mb-0 font-weight-bold">{{callRequestMessage?.creator?.first_name+ " "+callRequestMessage?.creator?.last_name}} <small class="text-italic font-weight-normal"> {{callRequestMessage.user_type}}</small></h5>

                                    <div class="d-flex">
                                        <div class="d-flex align-items-center">
                                            <small v-if="callRequestMessage.created_at !== callRequestMessage.updated_at" class=" font-weight-bold mb-1 mr-1 font-small-1 text-warning"> {{callRequestMessage.created_at !== callRequestMessage.updated_at ? ' Updated previously ' :''}} </small>
                                            <i v-if="isEditAble(callRequestMessage.creator)" class="text-danger bx bx-edit mb-1  cursor-pointer" title="Update Note" style="font-size: 15px;" @click="openMessageEditModal(callRequestMessage)"></i>

                                            <i v-if="isEditAble(callRequestMessage.creator)" class="text-danger bx bxs-message-alt-x mb-1 ml-1 cursor-pointer" style="font-size: 16px;" title="Delete Note" @click="openCallRequestMessageDeleteAlertModal(callRequestMessage)"></i>
                                        </div>

                                    </div>

                                    <!-- <span class=" font-weight-bold mb-1 badge badge-circle-primary font-small-1">{{callRequestMessage.type}}</span> -->
                                    <p class="font-small-3">{{callRequestMessage.message}}</p>
                                </div>
                            </div>
                            <hr>
                        </div>

                    </div>

                </div>
            </div>
            <div class="modal-footer border-0 pt-0">
                <button v-if="!isEditModalActive" type="button" class="btn btn-primary"  data-dismiss="modal">
                    <i class="bx bx-x d-block d-sm-none"></i>
                    <span class="d-none d-sm-block">Close</span>
                </button>
            </div>
             <div class="" data-toggle="modal" data-target="#CallRequestMessageEditModal"></div>
             <div class="" data-toggle="modal" data-target="#CallRequestMessageDeleteAlertModal"></div>
             <CallRequestMessageEditModal  @updateEditModalActiveStatus="updateEditModalActiveStatus(status)"/>
             <CallRequestMessageDeleteAlertModal @confirmModelDeletion="confirmModelDeletion" />
        </div>
    </div>
</div>
</template>

<script>
import {

mapActions,
    mapGetters
} from "vuex";
import CallRequestMessageEditModal from "./CallRequestMessageEditModal.vue";
import CallRequestMessageDeleteAlertModal from "./CallRequestMessageDeleteAlertModal.vue";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
export default {
    name: "CallRequestMessagesModal",
     mixins: [ShowToastMessage, Loader],
    props: [],
    data() {
        return {
            isEditModalActive: false,
            callRequestMessages: []
        };
    },
    computed: {
        ...mapGetters({
            // call request 
            // selectedCallRequest: "appCallRequests/callRequest",
            selectedCallRequestMessages: "appCallRequestMessages/callRequestMessages",
             selectedCallRequestMessage: 'appCallRequestMessages/callRequestMessage',
        }),
    },
    watch: {
        selectedCallRequestMessages(currentCallRequestMessages) {
          
                console.log("called");
                this.callRequestMessages = currentCallRequestMessages;
          
        }
    },
    methods: {
          ...mapActions({
            // call request message
           
            resetCallRequestMessage: 'appCallRequestMessages/resetCallRequestMessage',
            deleteCallRequestMessageOnList: 'appCallRequestMessages/deleteCallRequestMessageOnList',
        

           

           

         
          }),
       async openCallRequestMessageDeleteAlertModal(callRequestMessage) {
            this.selectedEditAbleNote = {};
            await this.resetCallRequestMessage();
            await this.$store.commit('appCallRequestMessages/SET_CALL_REQUEST_MESSAGE', callRequestMessage);
            this.isEditModalActive = true;
            document.querySelector('[data-target="#CallRequestMessageDeleteAlertModal"]').click();
        },
        async confirmModelDeletion (confirmModelDeletion)
        {
            console.log('asdf');
            if (confirmModelDeletion) {
                await this.deleteSingleCallRequestMessageOnLIst();
            }
            this.updateEditModalActiveStatus();

        },
         async deleteSingleCallRequestMessageOnLIst() {
            this.loader(true);
            const response = await this.deleteCallRequestMessageOnList(this.selectedCallRequestMessage.id);
            this.loader(false);
            if (response.status === 200 || response.status === 204) {
                const toastObj = {
                    message: "Call request message deleted successfully",
                    type: 'success'
                };
                this.showToastMessage(toastObj);

                return;

            }
            this.showToastMessage(response);

        },
        updateEditModalActiveStatus() {
            this.isEditModalActive = false
        },
        isEditAble(creator) {
            if ((creator.id === this.$store.getters["appAuthLogin/authUser"].id)) {
                return true;
            }
            return false;
        },
       async openMessageEditModal (callRequestMessage)
       {
            this.isEditModalActive = true;
            await this.resetCallRequestMessage();
            await this.$store.commit('appCallRequestMessages/SET_CALL_REQUEST_MESSAGE', callRequestMessage);
            document.querySelector('[data-target="#CallRequestMessageEditModal"]').click();
        }
    },
    components: { CallRequestMessageEditModal, CallRequestMessageDeleteAlertModal }
};
</script>

<style scoped>
.rounded-avatar {
    overflow: hidden;
    width: 60px;
    border-radius: 50% !important;
    border-radius: 50% !important;
    border: 2px solid #7698d9 !important;
    border-bottom: none !important;
    border-left: none !important;
    /* border-top: none  !important; */
}
</style>
