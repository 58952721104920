<template>
<div class="modal fade" id="callRequestMessagesAddModal" tabindex="-1" role="dialog" aria-labelledby="callRequestMessagesAddModal" aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Add reason</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <div class="row d-flex justify-content-center">
                        <div class="col-12">
                            <div class="form-group">
                                <label>Reason</label>
                                <textarea v-model="message" class="form-control" name="" id="" rows="10"></textarea>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="modal-footer border-0 pt-0">
                <button :disabled="this.message.trim().length === 0" @click="createCallRequestMessage()" type="button" class="btn btn-primary">
                    Add Reason
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {

    mapActions,
    mapGetters
} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
export default {
    name: "CallRequestMessagesAddModal",
    props: [],
    mixins: [ShowToastMessage, Loader],
    data() {
        return {
            callRequest: {},
            message: '',
        };
    },
    computed: {
        ...mapGetters({
            // call request 
            selectedCallRequest: 'appCallRequests/callRequest',

        }),

    },
    watch: {
        selectedCallRequest(currentCallRequest) {
            if (currentCallRequest ?.id) {
                console.log('called');
                this.callRequest = currentCallRequest;
            }
        }
    },
    methods: {
        ...mapActions({

            // call request message
            postCallRequestMessage: 'appCallRequestMessages/postCallRequestMessage',

        }),
        async createCallRequestMessage() {
            this.loader(true);
            const postCallRequestMessageData = {

                creator_id:this.$store.getters["appAuthLogin/authUser"].id,
                call_request_id: this.callRequest.id,
                message: this.message,

            };
            const response = await this.postCallRequestMessage(postCallRequestMessageData);
            this.loader(false);
            if (response.status === 201) {
                this.message = "";
                document.querySelector('[data-target="#callRequestMessagesAddModal"]').click();
                this.$emit('getCallRequestList');
              
            }
            if (response.message) {
                this.showToastMessage(response);
            }

         
           
        },

    },

};
</script>

<style scoped>

</style>
