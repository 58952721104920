<template>
<div class="modal fade" id="CallRequestUpdateModal" tabindex="-1" role="dialog" aria-labelledby="CallRequestUpdateModal" aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Notes</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div >
                    <div class="row" v-if="editableColumnName === 'priority'">
                        <div class="col-12">
                            <label>Select priority</label>
                            <VueMultiselect v-model="selectedPriority" class="" :options="priorityList"  placeholder="Select priority" label="name" track-by="value" :show-labels="false" :allow-empty="false" />
                        </div>
                    </div>
                  <div class="row" v-if="editableColumnName === 'status'">
                        <div class="col-12">
                            <label>Select status</label>
                            <VueMultiselect v-model="selectedStatus" class="" :options="statusList"  placeholder="Select status" label="name" track-by="value" :show-labels="false" :allow-empty="false" />
                        </div>
                    </div>

                </div>
            </div>
            <div class="modal-footer border-0 pt-0">
                <button :disabled="!isDisabled" @click="updateCallRequestPriority()" type="button" class="btn btn-primary">
                    save changes
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import {

    mapActions,
    mapGetters
} from "vuex";

export default {
    name: "CallRequestUpdateModal",
    props: ['callRequestEditableColumnName'],
    mixins: [ShowToastMessage, Loader],
    data() {
        return {
            callRequest: {},
            selectedPriority: {},
            selectedStatus: {},
            editableColumnName:'',
            priorityList: [{
                    name: 'Low',
                    value: 0
                },
                {
                    name: 'Medium',
                    value: 1
                },
                {
                    name: 'High',
                    value: 2
                }
            ],
            statusList: [{
                    name: 'Pending',
                    value: 0
                },
                {
                    name: 'Resolved',
                    value: 1
                },

            ],
        };
    },
    computed: {
        ...mapGetters({
            // call request 
            selectedCallRequest: 'appCallRequests/callRequest',

        }),
        isDisabled ()
        {
          if (this.editableColumnName === 'priority') {
            return ((this.callRequest?.id) && (this.selectedPriority?.name) && (this.callRequest.priority.toString().trim().toLowerCase() !== this.selectedPriority.name.toString().trim().toLowerCase()));
          }
          if (this.editableColumnName === 'status') {
            return ((this.callRequest?.id) && (this.selectedStatus?.name) && (this.callRequest.status.toString().trim().toLowerCase() !== this.selectedStatus.name.toString().trim().toLowerCase()));
          }

          return false;
        }

    },
    watch: {
        selectedCallRequest(currentCallRequest) {
            if (currentCallRequest ?.id) {
                this.callRequest = currentCallRequest;
                this.selectedPriority = this.priorityList.find(priority => priority.name.trim().toLowerCase() === currentCallRequest.priority.toString().trim().toLowerCase());
                if (this.selectedPriority === undefined) {
                    this.selectedPriority = {};
                }
                this.selectedStatus = this.statusList.find(status => status.name.trim().toLowerCase() === currentCallRequest.status.toString().trim().toLowerCase());
                if (this.selectedStatus === undefined) {
                    this.selectedStatus = {};
                }

            }
        },
      callRequestEditableColumnName(type){
          this.editableColumnName = type;
      }

    },
    methods: {
        ...mapActions({
            // call request
            // resetCallRequest: 'appCallRequests/resetCallRequest',
            putCallRequestOnList: 'appCallRequests/putCallRequestOnList',

        }),
      
        async updateCallRequestPriority() {
            const putCallRequestData = {
                id: this.callRequest.id,
                data: { }
                    // priority: this.selectedPriority.value

            };
            if (this.editableColumnName === 'status') {
              putCallRequestData.data.status = this.selectedStatus.value;
            }
            if (this.editableColumnName === 'priority') {
              putCallRequestData.data.priority = this.selectedPriority.value;
            }
            this.loader(true);
            const response = await this.putCallRequestOnList(putCallRequestData);
            this.loader(false);
            if (response.message) {
                this.showToastMessage(response);
            }
            if (response.status === 200) {
                document.querySelector('[data-target="#CallRequestUpdateModal"]').click();
                this.showToastMessage({type:'success',message:'Successfully updated !'});
            }
           

        },
    },

};
</script>

<style scoped>
.rounded-avatar {
    overflow: hidden;
    width: 60px;
    border-radius: 50% !important;
    border-radius: 50% !important;
    border: 2px solid #7698d9 !important;
    border-bottom: none !important;
    border-left: none !important;
    /* border-top: none  !important; */
}
</style>
