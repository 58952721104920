<template>
<AppLayout>
    <template v-slot:appContent>
        <section class="users-list-wrapper">
            <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
                <h4 class="">Call Request / List</h4>
                <div>
                    <router-link :to="{name:'appCallRequestCreate'}">
                        <span class="glow d-flex align-items-center">
                            <i class='bx bx-plus bx-flip-vertical'></i>&nbsp;Create</span>
                    </router-link>
                    <!-- <router-link :to="can('service-create') ? {name:'appCallRequestCreate'} : '#'">
                        <span :title="can('service-create') ? 'Create' : 'Create Forbidden'" class="glow d-flex align-items-center">
                            <i class='bx bx-plus bx-flip-vertical'></i>&nbsp;Create</span>
                    </router-link> -->
                </div>
            </div>
            <div class="users-list-filter px-1 my-2 py-2 border rounded">
                <div class="row align-items-center">
                    <div class="col-12 col-sm-12 col-md-3">
                        <div class="form-group">
                            <label>Priority</label>
                            <VueMultiselect v-model="selectedPriority" :options="priorityList" :close-on-select="true" label="name" track-by="value" :show-labels="false" :allow-empty="false" />
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-3">
                        <div class="form-group">
                            <label>Status</label>
                            <VueMultiselect v-model="selectedStatus" :options="statusList" :close-on-select="true" label="name" track-by="value" :show-labels="false" :allow-empty="false" />
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-3">
                        <div class="form-group">
                            <label>Read Status</label>
                            <VueMultiselect v-model="selectedReadStatus" :options="readStatusList" :close-on-select="true" label="name" track-by="value" :show-labels="false" :allow-empty="false" />
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-3">
                        <div class="form-group">
                            <label>Date</label>
                            <div class="d-flex align-items-center date-picker-wrapper">
                                <div class="w-100 ">
                                    <DatePicker v-model="createdDate" :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}" :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                                        <template v-slot="{ inputValue ,togglePopover  }">
                                            <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                                                <i class='bx bx-calendar mr-1'></i>
                                                <input class="form-control" :value="inputValue" style="cursor: pointer;" />
                                            </div>
                                        </template>
                                    </DatePicker>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-3 ">
                        <div class="form-group">
                            <label>Code</label>
                            <input v-model="reference" class="form-control " type="text" placeholder="Enter code">
                        </div>
                    </div>
                    <div class="col-12 col-md-3 ">
                        <div class="form-group">
                            <label>CUSTOMER/ADDRESS SEARCH</label>
                            <input v-model="getCallRequestsParams.where_has_user_search_query" class="form-control " type="text" placeholder="Enter Reference">
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-3">
                        <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0" @click="applyCallRequestFilter(null)">Filter
                        </button>
                    </div>

                    <!-- <div class="col-12 col-sm-12 col-md-4">
                        <label>Status</label>
                        <VueMultiselect :allow-empty="false" v-model="selectedStatus" class="" :options="status" :close-on-select="true" placeholder="Select status" label="name" track-by="value" :show-labels="false" />
                    </div> -->
                </div>
                <!-- <div class="row pt-2 d-flex justify-content-end">
                    <div class="col-12 col-sm-12 col-md-3">
                        <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0" @click="applyCallRequestFilter(null)">Filter
                        </button>
                    </div>
                </div> -->
            </div>
            <div class="users-list-table">
                <div class="card">
                    <div class="card-body">
                        <div class="table-responsive" ref="serviceListRef">
                            <table id="users-list-datatable" class="table">
                                <thead>
                                    <tr>
                                        <th class="position-relative" :style="{ width: '5%' }">SL</th>
                                        <th class="position-relative" :style="{ width: '10%' }">Code</th>
                                        <th class="position-relative" :style="{ width: '15%' }">Name</th>
                                        <th class="position-relative" :style="{ width: '10%' }">Read status</th>
                                        <th class="position-relative" :style="{ width: '10%' }">Priority</th>
                                        <th class="position-relative" :style="{ width: '10%' }">status</th>
                                        <th class="position-relative" :style="{ width: '15%' }">Tag To</th>
                                        <th class="position-relative" :style="{ width: '15%' }">Created at</th>
                                        <th class="position-relative" :style="{ width: '5%' }">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(callRequest, index) in callRequests" :key="index">
                                        <td>
                                            {{ index + 1 }}
                                        </td>

                                        <td>
                                            <span class="theme-color"> {{ callRequest.reference }}</span>
                                        </td>
                                        <td>
                                            <span v-if="callRequest?.user?.id" class="d-block">
                                                {{ `${callRequest.user.first_name} ${callRequest.user.last_name}` }}
                                            </span>
                                            <span v-if="callRequest?.user?.email" class="d-block">
                                                {{ callRequest.user.email }}
                                            </span>
                                            <span v-if="callRequest?.user?.phone_number" class="d-block">
                                                {{callRequest.user.phone_number}}
                                            </span>
                                        </td>
                                        <td>
                                            {{ callRequest.read_status }}
                                        </td>
                                        <td>
                                            <div class="cursor-pointer" @click="updateCallRequestModalOpenHandler(callRequest,'priority')">
                                                <span v-if="callRequest.priority === 'High'" class="text-warning">{{ callRequest.priority }} <i style="font-size: 14px;" class='bx bx-pencil'></i></span>
                                                <span v-else-if="callRequest.priority === 'Medium'" class="text-primary">{{ callRequest.priority }} <i style="font-size: 14px;" class='bx bx-pencil'></i></span>
                                                <span v-else>{{ callRequest.priority }} <i style="font-size: 14px;" class='bx bx-pencil'></i></span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="cursor-pointer" @click="updateCallRequestModalOpenHandler(callRequest,'status')">
                                                <span :class="callRequest.status === 'Pending' ? 'text-danger' : 'text-success'">{{ callRequest.status }} <i style="font-size: 14px;" class='bx bx-pencil'></i></span>
                                            </div>
                                        </td>
                                      <td>
                                        {{ callRequest.tag_reference }}
                                      </td>
                                        <td>
                                            {{ callRequest.created_at }}
                                        </td>

                                        <td>
                                            <div class="d-flex align-items-top justify-content-around">
                                                <div>
                                                    <i @click="viewMessagesModalOpen(callRequest)" title="view messages" class='bx bx-note cursor-pointer text-warning'></i>
                                                </div>
                                                <div>
                                                    <i @click="addMessageModalOpen(callRequest)" class='bx bx-comment-add text-success cursor-pointer' title="add message"></i>
                                                   
                                                </div>

                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <ListPagination position="right" :pagination-data="paginateLinks" @getClickedPage="applyCallRequestFilter" />
                        </div>
                    </div>
                </div>
                <div class="" data-toggle="modal" data-target="#ViewMessagesModal"></div>
                <div class="" data-toggle="modal" data-target="#CallRequestUpdateModal"></div>
                <!--                <div class="" data-toggle="modal" data-target="#callRequestPriorityUpdateModal"></div>-->
                <div class="" data-toggle="modal" data-target="#callRequestMessagesAddModal"></div>
                <CallRequestMessagesModal />
                <CallRequestUpdateModal :callRequestEditableColumnName="callRequestEditableColumnName" />
                <CallRequestMessagesAddModal @getCallRequestList="getCallRequestList" />
            </div>
        </section>
    </template>
</AppLayout>
</template>

<script>
// components
import AppLayout from "@/layouts/backEnd/AppLayout.vue";
import ListPagination from "@/components/backEnd/pagination/ListPagination";
import {
    DatePicker
} from 'v-calendar';
// import ServiceDeleteAlertModal from "@/components/backEnd/modal/DeleteAlertModal";
// import ServiceTipsCreateModal from "@/views/backEnd/services/includes/ServiceTipsCreateModal";
// import ServiceTipsViewModal from "@/views/backEnd/services/includes/ServiceTipsViewModal";
// mixins
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Authorization from "@/components/backEnd/mixins/Authorization";
import Loader from "@/components/backEnd/mixins/Loader";
// core packages
import {
    mapActions,
    mapGetters
} from "vuex";
import CallRequestMessagesModal from "./includes/CallRequestMessagesModal.vue";
import CallRequestUpdateModal from "./includes/CallRequestUpdateModal.vue";
import CallRequestMessagesAddModal from "./includes/CallRequestMessagesAddModal.vue";

export default {
    name: "CallRequestList",
    mixins: [ShowToastMessage, Loader, Authorization],
    components: {
        AppLayout,
        CallRequestMessagesModal,
        CallRequestUpdateModal,
        CallRequestMessagesAddModal,
        ListPagination,
        DatePicker
    },
    data() {
        return {
            createdDate: '',
            reference: '',
            priorityList: [{
                    value: '',
                    name: "Any",
                }, {
                    name: 'Low',
                    value: 0
                },
                {
                    name: 'Medium',
                    value: 1
                },
                {
                    name: 'High',
                    value: 2
                }
            ],
            selectedPriority: {
                value: '',
                name: "Any",
            },
            statusList: [{
                    value: '',
                    name: "Any",
                },
                {
                    name: 'Pending',
                    value: 0
                },
                {
                    name: 'Resolved',
                    value: 1
                },

            ],
            selectedStatus: {
                value: '',
                name: "Any",
            },
            readStatusList: [{
                    value: 0,
                    name: "Unseen",
                },
                {
                    name: 'Seen',
                    value: 1
                }, 

            ],
            selectedReadStatus: {
                value: '',
                name: "Any",
            },
            callRequestEditableColumnName: '',
            selectedPagination: {
                value: '',
                name: 'Default'
            },
            modelIdForDelete: '',
            getCallRequestsParams: {
                created_at:'',
                priority:'',
                status: '',
                reference:'',
                read_status:'',
                where_has_user_search_query:'',
                with_relation: ['callRequestMessages.creator', 'user'],
                order_by_date: 'ASC',
                paginate: 1,
                pagination: '',
                page: '',
                order_by_id: 'DESC',
                
            },

            getSettingsParams: {
                type: ["default"],
                key: ["default_pagination"],
            },
            status: [{
                    value: '',
                    name: "Any",
                }, {
                    value: 0,
                    name: "Inactive",
                },
                {
                    value: 1,
                    name: "Active",
                },
                {
                    value: "",
                    name: "Any",
                },
            ],

        };
    },
    computed: {
        ...mapGetters({
            // call requests
            callRequests: 'appCallRequests/callRequests',

            services: "appService/services",
            paginateLinks: "appCallRequests/paginateLinks",
        }),
        paginationList() {
            return [{
                value: this.$store.getters['appSettings/settingDefaultPagination'].value,
                name: 'Default'
            }, {
                value: 25,
                name: '25 Entries'
            }, {
                value: 50,
                name: '50 Entries'
            }, {
                value: 100,
                name: '100 Entries'
            }];
        },
        // serviceCategoryList() {
        //     let serviceCategories = this.serviceCategories ? this.serviceCategories.map(serviceCategory => {
        //         return {
        //             value: serviceCategory.id,
        //             name: serviceCategory.name
        //         }
        //     }) : [];

        //     return [{
        //             value: '',
        //             name: 'None'
        //         },
        //         ...serviceCategories,
        //     ];
        // },

    },
    watch: {
        selectedPagination(selectedPagination) {
            this.getServiceParams.pagination = selectedPagination.value;
        },
        
        selectedStatus (selectedStatus)
        {
            this.getCallRequestsParams.status = selectedStatus.value;
        },
        selectedPriority (selectedPriority)
        {
            this.getCallRequestsParams.priority = selectedPriority.value;
        },
        reference (reference)
        {
            this.getCallRequestsParams.reference = reference;
        },
        createdDate (currentDate)
        {
            this.getCallRequestsParams.created_at = currentDate;
        },
        selectedReadStatus(currentReadStatus){
            this.getCallRequestsParams.read_status = currentReadStatus.value
        }
       

       
    },

    methods: {
        ...mapActions({
            // call request
            getCallRequests: 'appCallRequests/getCallRequests',
            resetCallRequest: 'appCallRequests/resetCallRequest',
            putCallRequestOnList: 'appCallRequests/putCallRequestOnList',

            // callRequestMessage
            resetCallRequestMessages: 'appCallRequestMessages/resetCallRequestMessages',

            // settings
            getSettings: "appSettings/getSettings",

        }),

        async getSettingList() {
            this.loader(true);
            const response = await this.getSettings(this.getSettingsParams);
            this.loader(false);
            if (response.message) {
                this.showToastMessage(response);
            }
        },

        async onConfirmModelDeletion(confirmModelDeletion) {
            await this.deleteSingleServiceOnList(confirmModelDeletion.modelId);
        },

        async viewMessagesModalOpen(callRequest) {

            // await this.resetCallRequest();
            await this.resetCallRequestMessages();
            // await this.$store.commit('appCallRequests/SET_CALL_REQUEST', callRequest);

            if (callRequest.read_status !== 'Seen') {
                await this.updateCallRequest(callRequest);
            }
            const callRequestMessages = callRequest ?.callRequestMessages ?? []
            await this.$store.commit('appCallRequestMessages/SET_CALL_REQUEST_MESSAGES', callRequestMessages);
            if (callRequestMessages.length !== 0) {

                document.querySelector('[data-target="#ViewMessagesModal"]').click();
                return;
            }
            this.showToastMessage({
                type: 'error',
                message: 'No messages found !'
            });

        },
        async addMessageModalOpen(callRequest) {

            await this.resetCallRequest();
            await this.$store.commit('appCallRequests/SET_CALL_REQUEST', callRequest);
            document.querySelector('[data-target="#callRequestMessagesAddModal"]').click();

        },
        async updateCallRequestModalOpenHandler(callRequest, type) {
            if (callRequest.read_status !== 'Seen') {
                await this.updateCallRequest(callRequest);
            }
            this.callRequestEditableColumnName = '';
            this.callRequestEditableColumnName = type;
            await this.resetCallRequest();
            await this.$store.commit('appCallRequests/SET_CALL_REQUEST', callRequest);

            document.querySelector('[data-target="#CallRequestUpdateModal"]').click();

            // const callRequestMessage = callRequest?.callRequestMessages ?? []
            // if (callRequestMessage?.length !== 0) {

            //     return;
            // }

        },
        async getCallRequestList() {
            this.loader(true);
            const response = await this.getCallRequests(this.getCallRequestsParams);
            this.loader(false);

            if (response.message) {
                this.showToastMessage(response);
            }

        },
        async updateCallRequest(callRequest) {
            const putCallRequestData = {
                id: callRequest.id,
                data: {
                    read_status: 1
                }
            };
            this.loader(true);
            const response = await this.putCallRequestOnList(putCallRequestData);
            this.loader(false);
            if (response.message) {
                this.showToastMessage(response);
            }

        },
        async applyCallRequestFilter (pageNumber)
        {
             if (this.reference.length){
                this.getCallRequestsParams.priority = '';
                this.selectedPriority = { value: '', name: "Any"};
                this.getCallRequestsParams.status = '';
                this.selectedStatus = { value: '', name: "Any"};
                this.getCallRequestsParams.read_status = '';
                this.selectedReadStatus = { value: '', name: "Any"};
                this.getCallRequestsParams.created_at = '';
                this.createdDate = "";
                this.getCallRequestsParams.where_has_user_search_query = "";
            }
            this.getCallRequestsParams.page = pageNumber;
            await this.getCallRequestList();
        },

    },

    async mounted() {
        this.loader(true);
        await this.getSettingList();
        await this.getCallRequestList();
        // await this.getServiceList();
        // await this.loader(false);
    },

    beforeMount() {
        // this.resetServices();
    },

};
</script>

<style scoped>
</style>
