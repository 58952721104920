<template>
<div class="modal fade" id="CallRequestMessageEditModal" tabindex="-1" role="dialog" aria-labelledby="CallRequestMessageEditModal" aria-hidden="true"  data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Edit reason</h1>
                <button type="button" class="close" @click="closeModal">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <div class="row d-flex justify-content-center">
                        <div class="col-12">
                            <div class="form-group">
                                <label>Reason</label>
                                <textarea v-model="message" class="form-control" name="" id="" rows="10"></textarea>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="modal-footer border-0 pt-0">
                <button :disabled="isDisabled" @click="updateCallRequestMessage()" type="button" class="btn btn-primary">
                    Update Reason
                </button>
            </div>
            
        </div>
    </div>
</div>
</template>

<script>
import {

    mapActions,
    mapGetters
} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
export default {
    name: "CallRequestMessageEditModal",
    props: [],
    mixins: [ShowToastMessage, Loader],
    data() {
        return {
            callRequestMessage: {},
            message: '',
        };
    },
    computed: {
        ...mapGetters({
            // call request 
            selectedCallRequestMessage: 'appCallRequestMessages/callRequestMessage',

        }),

        isDisabled ()
        {
            if (this.message) {
                 return this.message.trim().length === 0;
            }
            return false;
           
        },
      

    },
    watch: {
        selectedCallRequestMessage(currentCallRequestMessage) {
            if (currentCallRequestMessage) {
                this.callRequestMessage = currentCallRequestMessage;
                this.message = this.callRequestMessage.message;
            }
        }
    },
    methods: {
        ...mapActions({

            // call request message
            postCallRequestMessage: 'appCallRequestMessages/postCallRequestMessage',
            putCallRequestMessageOnList: 'appCallRequestMessages/putCallRequestMessageOnList',

        }),
          closeModal ()
        {
              document.querySelector('[data-target="#CallRequestMessageEditModal"]').click();
              this.$emit('updateEditModalActiveStatus');
        },
        async updateCallRequestMessage() {
            this.loader(true);
            const postCallRequestMessageData = {
                id: this.callRequestMessage.id,
                data: {
                      message: this.message
                }
              
              

            };
            const response = await this.putCallRequestMessageOnList(postCallRequestMessageData);
            this.loader(false);
            if (response.status === 200) {
                this.message = "";
                document.querySelector('[data-target="#CallRequestMessageEditModal"]').click();
                this.$emit('updateEditModalActiveStatus');
              
            }
            if (response.message) {
                this.showToastMessage(response);
            }

         
           
        },

    },

};
</script>

<style scoped>

</style>
